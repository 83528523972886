import errorHandler from './handler/erroHandler';
import axiosInstance from './manager/manager.service';

export const baniereService = {
    create,
    update,
    get,
    del,
    updateStatus,

};

async function create(actu) {

    try {
        let ret = await axiosInstance.post(`/banieres`, actu);

        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function get() {

    try {
        let ret = await axiosInstance.get(`/banieres`);

        return ret.data;
    } catch (error) {
        console.log(error);
        return [];
    }

}

async function updateStatus(data, id) {
    try {
        let ret = await axiosInstance.put(`/banieres/status/${id}`, data);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}


async function update(data, id) {
    try {
        let ret = await axiosInstance.put(`/banieres/${id}`, data);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}


async function del(id) {
    try {
        let ret = await axiosInstance.delete(`/banieres/${id}`);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}


